import type {
  UseQueryOptions,
  QueryKey,
  UseQueryReturnType,
} from "@tanstack/vue-query";
import { useQuery } from "@tanstack/vue-query";
import type { NuxtError } from "nuxt/app";

type UseQuerySuccessReturnType<TData, TError> = UseQueryReturnType<
  TData,
  TError
> & {
  data: globalThis.Ref<TData>;
  isLoading: false;
  isSuccess: true;
  status: "success";
};

export const useSuspenseQuery = async <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    "enabled"
  >,
) => {
  const { $queryClient } = useNuxtApp();
  const query = useQuery(options, $queryClient);

  if (!query.isSuccess.value) {
    try {
      await query.suspense();
    } catch (e) {
      const error = e as NuxtError;
      error.fatal = true;
      throw createError(error);
    }
  }

  return query as UseQuerySuccessReturnType<TData, TError>;
};
